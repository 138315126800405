import { IconButton } from '@chakra-ui/react'
import { IoMdImage } from 'react-icons/io'
import { useIsEmployeeView } from '../../../hooks/id_token_claims'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_WHITE } from '../../../utils/style'
import { Tooltip } from '@chakra-ui/react'
import { ReactElement } from 'react'

// Retraction button that opens modal to start retractions
function RetractionScreenshotViewButton({
  openRetractionScreenshotModal,
}: {
  openRetractionScreenshotModal: () => void
}): ReactElement {
  const [isEmployeeView] = useIsEmployeeView()
  if (!isEmployeeView) return <></>

  return (
    <Tooltip label="View Retraction Screenshot">
      <IconButton
        aria-label="retraction button"
        bgColor={DOPPEL_INTERNAL_PURPLE}
        color={DOPPEL_WHITE}
        icon={<IoMdImage />}
        onClick={() => {
          openRetractionScreenshotModal()
        }}
        size={'xs'}
      />
    </Tooltip>
  )
}

export default RetractionScreenshotViewButton
